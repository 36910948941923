import React, { useState } from 'react'
import styled from 'styled-components'
// import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import { myContext } from '../../provider'
import Login from './auth/loginForm'
import { Helmet } from 'react-helmet'
import { getItem, isLoggedIn, logout } from "../services/auth"
import BackgroundFooter from '../images/footer-background.png';
import FooterLogo from '../images/svg/footer-logo.svg';
import BurgerMenu from '../images/svg/burger-menu.svg';

const MenuIcon = styled.button`
	position: absolute;
	top: 2rem;
	right: 2rem;
	cursor: pointer;
	z-index: 25;
`

const MenuLinks = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 100;
	
	height: 100vh;
	width: 100%;
	background: #E28579;
	
	background-attachment: fixed;
	position: fixed;
	z-index: 15;
	top: 0;
	right: 0;
	transition: transform 300ms;
	/* transform: translateX(100%); */
	transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")};
	overflow-x: hidden; 

	ul {
		list-style-type: none;
	}

	li {
		margin-top: 1rem;
	}
	a {
		text-decoration: none;
		color: white;
		font-size: 1.5rem;
		transition: color 300ms;

		:hover {
			color: #6034ff;
		}
	}
`

const LoginIcon = styled.button`
	position: fixed;
	top: 2rem;
	right: 2rem;
	flex-direction: column;
	justify-content: space-around;
	width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	cursor: pointer;
	z-index: 25;

	div {
		width: 1.5rem;
		height: 0.1rem;
		background: white;
		border-radius: 5px;
		transform-origin: 1px;
		position: relative;
		transition: opacity 300ms, transform 300ms;
		
		:first-child {
			transform: rotate(45deg);
		}

		:nth-child(2) {
			transform: rotate(-45deg);
		}
	}
`

const LoginForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 100;
		
	height: 100vh;
	width: 100%;
	background: #E28579;
	background-attachment: fixed;
	position: fixed;
	z-index: 26;
	top: 0;
	right: 0;
	transition: transform 300ms;
	/* transform: translateX(100%); */
	${'' /* transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")}; */}
	overflow-x: hidden;

	.closeBtn {
		position: fixed;
		top: 2rem;
		right: 2rem;
		flex-direction: column;
		justify-content: space-around;
		width: 1.5rem;
		height: 1.5rem;
		background: transparent;
		cursor: pointer;
		z-index: 25;
	}

`

const Layout = ({ children }) => {
	var user = {};
	if(isLoggedIn){
		user = {
			token: getItem("token"),
			loggedIn: true
		}
	}else{
		user = {
			token: "",
			loggedIn: false
		}
	}
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		site {
	// 			siteMetadata {
	// 				title
	// 			}
	// 		}
	// 	}
	// `)

	
	const [ login, showLogin ] = useState(false)
	return (
		<myContext.Consumer>
			{context => (
				<div className={context.isDark ? 'darkTheme' : 'lightTheme'}>
					<Helmet>
						<meta charset="uft-8" />
						<title>Club Mella</title>
					</Helmet>

						<header className="absolute z-10 lg:px-28 px-14 w-full">
							<Header/>
						</header>
						<div className="site-content flex-grow">
							{children}
						</div>

						{/* Mobile menu */}
						<MenuIcon 
							className={context.nav ? 'hambergerWhite flex md:hidden' : 'hambergerBlack flex md:hidden'}
							onClick={ () => context.changeNav()} 
							nav = { context.nav }
						>
							<img src={BurgerMenu} alt="" />
						</MenuIcon>
						{/* <MenuLinks nav={ nav }>*/}
						<MenuLinks 
							className={context.nav ? 'slideIn' : 'slideOut'}
							nav={ context.nav }
						>
							<ul className=''>
								<li><a href="/">Our App</a></li>
								<li><a href="/about">About Us</a></li>
								<li><a href="/events">Events</a></li>
								<li><a href="/">Blog</a></li>
								<li><a href="/">Carreers</a></li>
								{/* <li><button>Login</button></li> */}
							</ul>
						</MenuLinks>
						{/* END Mobile menu */}
						<footer className="pt-28 pb-36" style={{  
							backgroundImage: "url(" + BackgroundFooter + ")",
							backgroundPosition: 'center',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat'
							}}>
							<div className='container relative lg:grid grid-cols-3 lg:px-16 px-8 py-12 bg-cultured radius-40'>
								<div className='flex lg:flex-col flex-row-reverse justify-between items-center lg:items-start lg:mb-0 mb-16'>
									<div className=''>
										<div className='text-comorant text-2xl mb-6'>Apple Store</div>
										<div className='text-comorant text-2xl'>Google Play</div>
									</div>
									<div>
										<img src={FooterLogo} alt="" />
									</div>
									
								</div>

								<div className='text-base lg:flex lg:col-span-2 lg:justify-around'>
									<div className='lg:flex inline-flex	 flex-col lg:mr-0 mr-10 lg:mb-0 mb-16 align-top	'>
										<div className='font-semibold mb-5'>Site Map</div>
										<div className='mb-2'><a href="/about">About Us</a></div>
										<div className='mb-2'><a href="/">Our Apps</a></div>
										<div className='mb-2'><a href="/blog">Blog</a></div>
										<div className='mb-2'><a href="/events">Events</a></div>
										<div className='mb-2'><a href="/about">Contact</a></div>
									</div>

									<div className='lg:flex inline-flex	 flex-col lg:mr-0 mr-10 lg:mb-0 mb-16 align-top	'>
										<div className='font-semibold mb-5'>Support</div>
										<div className='mb-2'><a href="/about">Careers</a></div>
										<div className='mb-2'><a href="/privacy">Privacy</a></div>
										<div className='mb-2'><a href="/terms">Terms</a></div>
									</div>

									<div className='lg:flex inline-flex flex-col lg:mr-0 mr-10 lg:mb-0 mb-16 align-top	'>
										<div className='font-semibold mb-5'>Social Media</div>
										<div className='mb-2'>Instagram</div>
										<div className='mb-2'>Facebook</div>
									</div>
								</div>


								{/* <div>
									<button
										onClick={ () => context.changeTheme()}
									>
										{ context.isDark ? "Light" : "Dark"}

									</button>
								</div> */}
								<div className='copyright-left text-white text-sm'>© 2023 ELF Media LLC, A Delaware Corporation</div>
								<div className='copyright-right text-white text-sm'>All rights reserved</div>
							</div>
							
						</footer>
								
								{/* Login form */}
								<LoginForm className={ context.login ? 'slideIn' : 'slideOut' }>
								<LoginIcon
									className='flex'
									onClick={ () => context.changeLogin()}
								>	
									<div />
									<div />
								</LoginIcon>
								<Login />
							</LoginForm>
							{/* END Login form */}

				</div>
			)}
		</myContext.Consumer>

	)
}
export default Layout