import React, { useContext } from "react";
import { Fragment } from 'react';
import * as Logo from './svg/gogoblockLogo'
import PathBar from './pathBar'
import {Link, navigate} from 'gatsby'
import { myContext } from '../../provider'
import { getItem, isLoggedIn, logout } from "../services/auth"
import { Menu, Transition } from '@headlessui/react'
import HeaderLogo from '../images/svg/header-logo.svg';
import HeaderLogoBlack from '../images/svg/header-logo-black.svg';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loggin: isLoggedIn(),
            white_header: false,
            navigation: [
                { name: 'Our App', href: '/' },
                { name: 'About Us', href: '/about' },
                { name: 'Events', href: '/events' },
                { name: 'Blog', href: '/blog' },
                { name: 'Careers', href: '#' },
              ]
        };
        this.onPop = this.onLoggout.bind(this);
        if (typeof window !== `undefined`){
            console.log("PROPS HEADER LOCATION: ", window.location.pathname);
            var currentPath = window.location.pathname;
            if(currentPath.includes("article")){
                this.state.white_header = true;
            }
          }

        // if(isLoggedIn) {
        //     this.contextType.setAuthenticated(true);
        //     console.log("Here 1");
        // }else{
        //     this.contextType.setAuthenticated(false);
        //     console.log("Here 2");
        // }
        
    }


    componentDidMount() {
        const user = this.context
        this.state.is_loggin = isLoggedIn();
      }

    onLoggout = (e, changeIsLoggin) => {
        e.preventDefault()
        //this.state.is_loggin = false;
        console.log(e);
        logout(() => window.location.href = '/')
        //changeIsLoggin();
      };

    render() {
        const isWhiteHeader = this.state.white_header;
        let headerLogo;
        let colorLink;
        let downloadLink;
        if(isWhiteHeader){
            headerLogo = <img className="w-auto" src={HeaderLogoBlack} alt="" />
            colorLink = "text-onyx hover:text-gray-700";
            downloadLink = "text-onyx hover:text-gray-700 border-black";
        }else{
            headerLogo = <img className="w-auto" src={HeaderLogo} alt="" />
            colorLink = "text-white hover:text-indigo-50";
            downloadLink = "text-white hover:text-indigo-50 border-white";
        }
        return (
            <myContext.Consumer>
                {context => (
                    <>
                    <nav className="mx-auto" aria-label="Top">
                        <div className="flex w-full items-center justify-between py-6 lg:border-none pr-14 md:pr-0">
                            <div className="flex items-center">
                                <a href="/">
                                <span className="sr-only">Club Mella</span>
                                {headerLogo}
                                </a>
                                <div className="ml-10 hidden space-x-8 lg:block md:block">
                                {this.state.navigation.map((link) => (
                                    <a key={link.name} href={link.href} className={colorLink + " text-base font-medium"}>
                                    {link.name}
                                    </a>
                                ))}
                                </div>
                            </div>
                            <div className="ml-10 space-x-4">
                                <a className={downloadLink + "text-base font-medium border py-2.5 px-6 rounded-full"}>
                                    Download
                                </a>
                            </div>
                        </div>
                    </nav>
                    </>
                )}
            </myContext.Consumer>
        );
    }
}
export default Header